import React, { useState, useEffect } from 'react';
import {
    IonBadge, IonButton, IonIcon,
    IonModal, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonContent,
    IonText,
    IonRow,
    IonCol
} from '@ionic/react';
import { notificationsOutline, close, closeOutline, chatbubblesOutline, 
    checkmarkCircleOutline, checkmarkCircleSharp, checkmarkCircle,
    closeCircleOutline, closeCircleSharp, closeCircle,
    alertCircleOutline, alertCircleSharp, alertCircle,
    informationCircleOutline, informationCircleSharp, informationCircle,
    boatOutline,
    mailUnreadOutline,
    cardOutline,
    checkmarkDoneOutline,
 } from 'ionicons/icons';

import { format } from 'date-fns';

import './Notification.css';

import axiosCall from '../data/axios';

const formatTimestamp = (timestamp: any) => {
    return format(new Date(timestamp), 'PPpp'); // PPpp gives a readable format like June 6, 2024 at 11:37 AM
};

const NotificationModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (isOpen) {
            // Fetch notifications from the server when the modal opens
            const fetchNotifications = async () => {
                try {
                    const response = await axiosCall('/api/notifications', 'get', null, true);
                    setNotifications(response.data);

                    const notificationIds: any[] = [];

                    await response.data.forEach((notif: any) => notificationIds.push(notif.id));

                    const markAsRead = await axiosCall('/api/notifications/mark-read', 'post',
                        { notificationIds: notificationIds },
                        true);
                } catch (error) {
                    console.error('Error fetching notifications:', error);
                }
            };

            fetchNotifications();
        }
    }, [isOpen]);

    return (
        <IonModal id="notification-modal" backdropDismiss={true} isOpen={isOpen} onDidDismiss={onClose} onClick={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Notifications</IonTitle>
                    <div slot="end" className="ion-padding" onClick={onClose}><IonIcon size="large" color="danger" icon={closeOutline} /></div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList lines="full">
                    {notifications.map((notification: any) => (
                        <IonItem key={notification.id}>
                            <IonRow>
                                <IonCol size="1" className="ion-align-self-center">
                                    {notification.type === 'order_completed' ? 
                                        <IonIcon size="small" color="success" icon={checkmarkDoneOutline}></IonIcon> : 
                                    notification.type === 'order_confirmed' ? 
                                        <IonIcon size="small" color="secondary" icon={boatOutline}></IonIcon> :
                                    notification.type === 'order' ?
                                        <IonIcon size="small" color="warning" icon={mailUnreadOutline}></IonIcon> :
                                    notification.type === 'order_rejected' ?
                                        <IonIcon size="small" color="danger" icon={alertCircle}></IonIcon> :
                                    notification.type === 'payment_confirmed' ?
                                        <IonIcon size="small" color="primary" icon={cardOutline}></IonIcon> :
                                        <IonIcon size="small" color="primary" icon={chatbubblesOutline}></IonIcon>
                                    }
                                </IonCol>
                                <IonCol size="11" className="ion-align-self-center">
                                    <IonItem>
                                        <IonText className="ion-padding">{notification.message}</IonText>
                                        <p style={{ fontSize: "small" }} className="ion-padding">{formatTimestamp(notification.created_at)}</p>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonItem>
                    ))}
                </IonList>
            </IonContent>
        </IonModal>
    );
};

const NotificationButton = () => {
    const [notificationCount, setNotificationCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Fetch initial notification count from the server
        const fetchNotificationCount = async () => {
            try {
                const response = await axiosCall('/api/notifications/count', 'get', null, true);
                setNotificationCount(response.data.count);
            } catch (error) {
                console.error('Error fetching notification count:', error);
            }
        };

        fetchNotificationCount();

        // Optionally, set up a polling interval to refresh the count
        const intervalId = setInterval(fetchNotificationCount, 15 * 60000); // Every minute

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    const readNotification = async () => {
        setIsModalOpen(true);
        setNotificationCount(0);
    };

    return (
        <>
            <IonIcon color="drukre" icon={notificationsOutline} onClick={() => setIsModalOpen(true)} />
            {notificationCount > 0 && (
                <IonBadge color="danger" onClick={() => readNotification()}>{notificationCount}</IonBadge>
            )}
            <NotificationModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};

export default NotificationButton;

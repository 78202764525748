import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, 
    IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonProgressBar, IonRow, 
    IonSearchbar, IonTitle, IonToolbar, 
    useIonLoading,
    useIonToast, useIonModal} from "@ionic/react";
import { cart, cartOutline, checkmark, chevronBackOutline, personCircle, search, searchOutline } from "ionicons/icons";
import { useEffect, useRef, useState, useContext } from "react";
import { useHistory, useParams } from "react-router";

import { OverlayEventDetail } from '@ionic/core/components';

import AuthContext from "../UserContext";

import axiosCall from '../data/axios';

import styles from "./CategoryProducts.module.css";

import UserStatus from "../components/UserStatus";

function validateEmail(email: string) {
	const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
	return re.test(String(email).toLowerCase());
}

const UpdateModal = ({ updateType, dismiss }: { updateType: any, dismiss: (data?: any | null | undefined | number, role?: string) => void }) => {
    const inputRef = useRef<HTMLIonInputElement>(null);
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Update Request Form</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
            <IonItem className="ion-padding">
                To update your {updateType}, we need you to validate it, 
                using the link that we will send you once you click the confirm button.
            </IonItem>
          <IonItem>
            <IonInput ref={inputRef} labelPlacement="stacked" label={`Enter your new ${updateType}`} placeholder={`Your ${updateType}`} />
          </IonItem>
        </IonContent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => dismiss({type: updateType, value: inputRef.current?.value}, 'confirm')} strong={true}>
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonPage>
    );
};

const MyAccount = () => {

    const history = useHistory();

	const { authValues } = useContext(AuthContext);

	useEffect(() => {
		if (!authValues.authenticated) {
		  history.push('/login');
		}
	}, [authValues]);

    const params = history.location.state as { data: string };

    const [email, setEmail] = useState<string>(authValues.user?.data?.email);
	const [password, setPassword] = useState<string>("");
	const [name, setName] = useState<string>(authValues.user?.data?.name);
    const [phone, setPhone] = useState<string>(authValues.user?.data?.phone);
	const [iserror, setIserror] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("");

    const spinnerOptions = {
		message: 'Please wait...',
		animated: true,
		cssClass: 'drukre-loading',
		showBackdrop: true
	};

	const [spinnerPresent, spinnerDismiss] = useIonLoading();

    const [presentToast] = useIonToast();

    const handleSave= () => {
		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}
		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}

		const rData = {
			"name": name,
            "email": email
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/user", 'put', rData, true)
			.then((res: any) => {
				spinnerDismiss();
				presentToast({
                    message: 'Account updated successfully',
                    icon: checkmark,
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
			})
			.catch((error: any) => {
				setMessage("Unable to Save. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});

	};

	const handlePasswordUpdate = () => {

		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}

		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}
		const resetData = {
			"email": email
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/resetPassword", 'post', resetData, true)
			.then((res: any) => {
				spinnerDismiss();
				presentToast({
                    message: 'Reset password link sent to your email. Please check your email.',
                    icon: checkmark,
                    color: 'success',
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ]
                });
			})
			.catch((error: any) => {
				setMessage("Unable to reset. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});
	};

    const handlePhoneEmailUpdate = (resetData: any) => {

		if (!resetData.type) {
			setMessage("Please enter a valid data");
			setIserror(true);
			return;
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/sendVerifyLink", 'post', resetData, true)
			.then((res: any) => {
				spinnerDismiss();
				presentToast({
                        message: `A link is sent to your ${resetData.type} ${resetData.value}. Please validate.`,
                        icon: checkmark,
                        color: 'success',
                        buttons: [
                            {
                                text: 'Dismiss',
                                role: 'cancel'
                            }
                        ]
                    });
			})
			.catch((error: any) => {
				setMessage("Unable to verify account. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});
	};

    const [updateType, setUpdateType] = useState('');

    const [present, dismiss] = useIonModal(UpdateModal, 
        {updateType, dismiss: (data: string, role: string) => dismiss(data, role)}
    );
    
      function openModal(type: string) {

        setUpdateType(type);

        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
				
					handlePhoneEmailUpdate(ev.detail.data);
                    
                }
            }
        });
    }

    return (

        <IonPage id="category-page" className={ styles.categoryPage }>
            <IonHeader>
                <IonToolbar>

				</IonToolbar>
			</IonHeader>
			
			<IonContent className="ion-padding ion-text-center">
                <IonToolbar>
                    
				</IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol size-md="6" offset-md="3">
                            <IonRow>
                                <IonCol>
                                    <IonLabel color="danger">{message}</IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonIcon
                                        color="drukre"
                                        style={{ fontSize: "70px" }}
                                        icon={personCircle}
                                    />
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <UserStatus />
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="floating"> Name </IonLabel>
                                        <IonInput type="text" value={name} onIonChange={(e) => setName(e.detail.value!)}>
                                        </IonInput>
                                    </IonItem>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="floating"> Mobile Number</IonLabel>
                                        <IonInput disabled type="number" value={phone}>
                                        </IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonButton color="drukre" onClick={() => openModal('phone')}>Update/Verify Phone</IonButton>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonItem>
                                        <IonLabel position="floating"> Email</IonLabel>
                                        <IonInput disabled type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} >
                                        </IonInput>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonButton color="drukre" onClick={() => openModal('email')}>Update/Verify Email</IonButton>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <IonButton color="drukre" onClick={handleSave}>Save</IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton color="drukre" onClick={handlePasswordUpdate}>Reset Password</IonButton>
                                </IonCol>
                            </IonRow>

                            <IonRow>
                                <IonCol>
                                    <p style={{ fontSize: "small" }}>
                                        This site is protected by reCAPTCHA and the Google 
                                        <a href="https://policies.google.com/privacy" target="_blank"> Privacy Policy</a> and 
                                        <a href="https://policies.google.com/terms" target="_blank"> Terms of Service</a> apply.
                                    </p>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default MyAccount;
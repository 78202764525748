import React, { useState, useRef, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonImg,
  IonLabel,
  IonIcon,
  IonButton,
  IonicSlides
} from '@ionic/react';
import { globeOutline, closeOutline, iosCloseCircle } from "ionicons/icons";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Keyboard, Pagination, Scrollbar, Zoom, EffectFade } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import 'swiper/css/effect-fade';
import '@ionic/react/css/ionic-swiper.css';
import './Slide.css';

export const SwiperZoomModal = ({zoomModalDismiss, images, clickedImageKey }) => {

	const slideOpts = {
		initialSlide: clickedImageKey,
		slidesPerView: 1,
		speed: 400,
		spaceBetween: 5,
    	centeredSlides: true,
		zoom: {
			maxRatio: 5,
			toggle: true
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		loop: true,
	  };

	return (
		<IonContent className="ion-padding">
			<IonIcon className="zoom-slide-close ion-float-right" icon={closeOutline} onClick={() => zoomModalDismiss()}/>
			<Swiper className="swiper_zoom_container" modules={[Navigation, Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]} 
				options={slideOpts} 
				initialSlide={clickedImageKey}

				autoplay={false}
				keyboard={true}
				navigation
				pagination={true}
				scrollbar={true}
				zoom= {{
					enabled: true,
					maxRatio: 5, // Maximum zoom ratio
					toggle: true, // Enable zoom toggle on double tap
					containerClass: 'swiper-zoom-container',
					zoomedSlideClass: 'swiper-slide-zoomed'
				}}
				mousewheel= {{
					invert: false,
					sensitivity: 1,
					eventsTarget: 'swiper-zoom-container'
				}}
				style={
					{
					"--swiper-navigation-color": "#fff", 
					"--swiper-pagination-color": "#fff"
					}
				} 
			>
				{images.map((image, i) => (
					<SwiperSlide key={i} >
						<div className="swiper-zoom-container slider-slide">
							
							<IonImg src={image.src ? image.src : `${process.env.REACT_APP_PRODUCT_ASSETS}/${image}`} className="swiper-zoom-target zoom-image"/>
						
						</div>
					</SwiperSlide>
				))}
			</Swiper>
				{/**
			<IonSlides clasName="slider" pager={true} navigation={true} options={slideOpts} zoom={true}>
				{images.map((image, i) => (
					<IonSlide key={i} >
						<div className="swiper-zoom-container slider-slide">
						
							<IonImg src={`${process.env.REACT_APP_BASE_URL}/images/${image}`} className="swiper-zoom-target zoom-image"/>
						
						</div>
					</IonSlide>
				))}
			</IonSlides>
				**/}
		</IonContent>
	);
	
}
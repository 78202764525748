import React, { useState, useEffect, useRef } from 'react';
import {
    IonBadge, IonButton, IonIcon,
    IonModal, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonContent,
    IonText,
    IonRow,
    IonCol,
    useIonLoading,
    useIonToast,
    IonNote,
    IonInput,
    useIonModal,
    IonPage,
    IonCard,
    IonCardHeader,
    IonSegment,
    IonSegmentButton,
    IonCardSubtitle,
    IonCardContent,
    IonSelect,
    IonSelectOption,
    IonItemDivider,
    IonButtons
} from '@ionic/react';
import { notificationsOutline, close, closeOutline, chatbubblesOutline, checkmarkSharp, alertSharp, checkmark, copySharp } from 'ionicons/icons';

import { format } from 'date-fns';

import './Notification.css';

import axiosCall from '../data/axios';

import { getCaptchaToken } from "../Helper";

import AuthContext from "../UserContext";
import { useHistory } from 'react-router';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';

const formatTimestamp = (timestamp: any) => {
    return format(new Date(timestamp), 'PPpp'); // PPpp gives a readable format like June 6, 2024 at 11:37 AM
};

interface RMAPaymentGatewayProps {
    total: number;
    payFunction: (event: any) => Promise<void>;
    purchaserData: {
        isExistingUser: boolean;
        isUserFormValid: boolean;
        userData: {};
    };
    onSuccess: any;     // Assuming api is optional
}

export const RMAPaymentGateway : React.FC<RMAPaymentGatewayProps> = ({ 
    total,
    payFunction,
    purchaserData,
    onSuccess
}) => {

    const [error, setError] = useState('');
    const [rmaInitMsg, setRmaInitMsg] = useState('Communicating with');

    const OtpModal = ({ dismiss }: { dismiss: (data?: any | null | undefined | number, role?: string) => void }) => {
        const inputRef = useRef<HTMLIonInputElement>(null);
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle> Authorise Payment</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonItem className="ion-padding">
                        Please enter the OTP code that would have been sent to your registered mobile number.
                    </IonItem>
                    <IonRow className="ion-padding">
                        <IonCol size="8" offset='2' className="ion-text-center">
                            <IonInput ref={inputRef} labelPlacement="floating" 
                                fill="outline" label="Enter the code here" type="number" />
                        </IonCol>
                    </IonRow>
                    <IonItemDivider/>
                    <IonItem>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                                    Cancel
                                </IonButton>
                            </IonButtons>
                            <IonButtons slot="end">
                                <IonButton color="drukre" onClick={() => dismiss({ otpCode: inputRef.current?.value }, 'confirm')} strong={true}>
                                    Submit
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonItem>
                </IonContent>
            </IonPage>
        );
    };

    const [bfsTxnId, setBfsTxnId] = useState('');
    const [bfsBankList, setBfsBankList] = useState([]);

    const [rmaBank, setRmaBank] = useState('');
    const [rmaAccountNo, setRmaAccountNo] = useState('');

    const [presentOTP, setPresentOTP] = useIonModal(OtpModal, {
        dismiss: (data: string, role: string) => setPresentOTP(data, role)
    });
    
    const [spinnerPresent, spinnerDismiss] = useIonLoading();

    const processOTP = async () => {

        setError('');

        if (!rmaBank || !rmaAccountNo) {
            setError("Please select a bank and enter account number.");
            return;
        }

        var captchaData = {
            captchaToken: '',
            captchaAction: ''
        };

        await getCaptchaToken('PAYMENT').then((token: any) => { // Here wait token generated
            if (token) {
                captchaData.captchaToken = token;
                captchaData.captchaAction = 'PAYMENT';
            }
        });

        spinnerPresent({
            message: 'Processing. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });

        //Fire backend rma api call
        axiosCall("/api/rma-payment-ae", 'post', 
            { bank: rmaBank, accountNo: rmaAccountNo, transactionId: bfsTxnId, captchaData: captchaData }, true)
        .then((res) => {
            if (res.data.status == '00'){
                presentOTP({
                    initialBreakpoint: 0.5,
                    breakpoints: [0, 0.4, 0.7, 1],
                    backdropBreakpoint: 0,
                    onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                        if (ev.detail.role === 'confirm') {
                            (ev.detail as any).rmaBank = rmaBank;
                            (ev.detail as any).rmaAccountNo = rmaAccountNo;
                            (ev.detail as any).bfs_bfsTxnId = bfsTxnId;
                            afterOTP(ev);
                        }
                    }
                });
            } else {
                setError('Payment process unsuccessful! ' + res.data.data);
                setRmaInitMsg('Reinitiating');
            }
        }).catch((error) => {
            setError("Payment initiation unsuccessful! Please try again.");
            setRmaInitMsg('Reinitiating');
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });
    };

    function afterOTP(ev: any) {

        setPresentOTP();

        payFunction(ev);
    }

    useEffect(() => {
        
        if(total < 1) return;

        let isMounted = true;
        
        const rmaIntent = async () => {
            try {
                var captchaData = {
                    captchaToken: '',
                    captchaAction: ''
                };

                await getCaptchaToken('PAYMENT').then((token:any) => {
                    if (token) {
                        captchaData.captchaToken = token;
                        captchaData.captchaAction = 'PAYMENT';
                    }
                });

                if (isMounted) {
                    spinnerPresent({
                        message: rmaInitMsg + ' Payment Gateway. Please wait...',
                        spinner: 'bubbles',
                        animated: true,
                        cssClass: 'drukre-loading'
                    });

                    axiosCall("/api/rma-payment-intent", 'post', 
                        { captchaData: captchaData, amount: total }, true)
                    .then((res) => {
                        if (isMounted) {
                            setBfsTxnId(res.data.data.bfs_bfsTxnId);
                            setBfsBankList(res.data.data.bfs_bankList);
                        }
                    })
                    .catch((error) => {
                        if (isMounted) {
                            setRmaInitMsg('Reinitiating');
                        }
                    })
                    .finally(() => {
                        if (isMounted) {
                            spinnerDismiss();
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching captcha token:', error);
            }
        };

        rmaIntent();

        return () => {
            isMounted = false;
        };
    }, [rmaInitMsg, total]);

    return (
        <IonCard>
            <IonCardHeader>
                <IonCardSubtitle className="ion-text-center"> </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonLabel position="stacked">Your order will be placed through RMA's Payment Gateway.</IonLabel>
                <IonRow>
                    <IonCol>
                        <IonSelect placeholder="Select Bank" onIonChange={(e) => setRmaBank(String(e.detail.value))}>
                            {bfsBankList &&
                            Object.entries(bfsBankList).map(([key, value]) => (
                                <IonSelectOption key={key} value={key}>{value}</IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonInput type="number" labelPlacement="floating" fill="outline" label="Account Number" 
                            placeholder="Enter Account Number" 
                            onIonChange={(e) => setRmaAccountNo(String(e.detail.value))} required />
                    </IonCol>
                </IonRow>
                {error && <p className="ion-text-center"><IonLabel color="danger">{error}</IonLabel></p>}
                <IonButton className="ion-padding ion-float-right" color="drukre" onClick={processOTP} disabled={!purchaserData.isUserFormValid}>
                    <IonIcon icon={checkmarkSharp} />&nbsp; Pay With RMA
                </IonButton>
            </IonCardContent>
        </IonCard>
    );
};

const PaymentForm = ({ purchaserData, payLoad, api, total, onSuccess }: {purchaserData: any, 
    payLoad:any, api: any, total: number, onSuccess: any}) => {
    const { authValues, fetchUser, logout } = React.useContext(AuthContext);
    const [name, setName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [exp, setExp] = useState('');
    const [cvv, setCvv] = useState('');
    const [error, setError] = useState('');
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const [spinnerPresent, spinnerDismiss] = useIonLoading();
    const [presentToast] = useIonToast();
    const history = useHistory();

    const [ paymentMode, setPaymentMode ] = useState<string>('rma');
    const [ walletBalance, setWalletBalance ] = useState(0);

    const [stagedAmount, setStagedAmount] = useState(0);
    const [stagedRef, setStagedRef] = useState('');

    useEffect(() => {
        axiosCall("/api/wallet", 'get', null, true).then((res) => {
            setWalletBalance(res.data.balance);
        })
        .catch((error) => {
            //setError("Couldn't fetch wallet balance.");
            //setIserror(true);
        });
    }, []);

    const handlePayment = async (event: any) => {

        event.preventDefault();

        const cardObject = {
            name: name,
            cardNumber: cardNumber,
            exp: exp,
            cvv: cvv
        };

        setError('');

        if (paymentMode == 'card' && (Number(cardNumber) < 444411111111 || Number(cvv) < 975)) {
            setError("Invalid Card Details");
            return;
        }

        if(paymentMode == 'wallet' && total > walletBalance)
        {
            setError("Insufficient wallet balance. Please add funds first.");
            return;
        }

        const otpData = {
            code: '',
            rmaAccountNo: '',
            rmaBank: '',
            bfsTransactionId: ''
        };

        if(paymentMode == 'rma') {
            otpData.code = event.detail.data.otpCode;
            otpData.rmaAccountNo = event.detail.rmaAccountNo;
            otpData.rmaBank = event.detail.rmaBank;
            otpData.bfsTransactionId = event.detail.bfs_bfsTxnId;
        }

        // Send paymentMethod.id to your server to create a payment intent
        // For example:
        // const response = await fetch('/create-payment-intent', {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({ payment_method: paymentMethod.id }),
        // });
        // const paymentIntent = await response.json();

        const headers = {
            'Content-Type': 'application/json'
        }

        var captchaData = {
            captchaToken: '',
            captchaAction: ''
        };

        await getCaptchaToken('PAYMENT').then((token: any) => { // Here wait token generated
            if (token) {
                captchaData.captchaToken = token;
                captchaData.captchaAction = 'PAYMENT';
            }
        });

        spinnerPresent({
			message: 'Processing. Please wait...',
			spinner: 'bubbles',
			animated: true,
			cssClass: 'drukre-loading'
		});

        axiosCall(api, 'post', 
            { 
                cardObject: cardObject,
                paymentMethod: paymentMode,
                otpData: otpData,
                payLoad: payLoad, 
                purchaser_details: purchaserData, 
                captchaData: captchaData 
            }, 
            true
        ).then((res) => {
            console.log(res);
            if (res.data.status !== 1){
                setError('Payment unsuccessful! ' + res.data.message);
            }
            else {
                setPaymentSuccess(true);
                onSuccess();
                presentToast({
                    message: 'Payment Successful! Your order has been placed.',
                    duration: 10000,
                    color: 'success'
                });
            }
        })
        .catch((error) => {
            setError("Unsuccessful! " + error);
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });

    };

    const addFundToWallet = async () => {
            
        if (stagedAmount < 1) {
            setError("Please enter a valid amount to add.");
            return;
        }

        if (!stagedRef) {
            setError("Please enter a reference or description identifiable for confirmation.");
            return;
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        var captchaData = {
            captchaToken: '',
            captchaAction: ''
        };

        await getCaptchaToken('PAYMENT').then((token:any) => { // Here wait token generated
            if (token) {
                captchaData.captchaToken = token;
                captchaData.captchaAction = 'PAYMENT';
            }
        });

        spinnerPresent({
            message: 'Processing. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });
        
        axiosCall("/api/wallet", 'post',
            {
                amount: stagedAmount,
                description: stagedRef,
                type: 'credit',
                captchaData: captchaData
            }, true
        ).then((res) => {
            console.log(res);
            if (res.data.status !== 1){
                setError('Fund submission unsuccessful! ' + res.data.message);
            }
            else {
                setWalletBalance(walletBalance + stagedAmount);
                presentToast({
                    message: 'Fund submission successfully! You will be notified when it becomes available in your wallet.',
                    duration: 3000,
                    color: 'success'
                });
            }
        })
        .catch((error) => {
            setError("Fund submission unsuccessful! Please try again.");
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });
    };

    const copyToClipboard = (text:any) => {
        if (!navigator.clipboard) {
            console.error('Clipboard functionality is not available.');
            // Optionally, present a toast message to inform the user
            presentToast({
                message: 'Clipboard functionality is not available in your browser.',
                duration: 3000,
                color: 'danger'
            });
            return;
        }
    
        navigator.clipboard.writeText(text).then(() => {
            presentToast({
                message: 'Account number copied to clipboard.',
                duration: 3000,
                color: 'success'
            });
        }).catch(err => {
            console.error('Failed to copy text: ', err);
            // Optionally, present a toast message to inform the user of the error
            presentToast({
                message: 'Failed to copy text to clipboard.',
                duration: 3000,
                color: 'danger'
            });
        });
    };

    return (
        <form onSubmit={handlePayment}> 
            <IonCard>
                <IonCardHeader>
                    <IonToolbar>
                        <IonSegment onIonChange={e => setPaymentMode(String(e.detail.value) || 'rma')}>
                            <IonSegmentButton value="rma">
                                <IonLabel>Pay via RMA</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="wallet">
                                <IonLabel>Pay With Wallet</IonLabel>
                            </IonSegmentButton>
                            {/*
                            <IonSegmentButton value="card">
                                <IonLabel>Pay With Card</IonLabel>
                            </IonSegmentButton>
                            */}
                        </IonSegment>
                    </IonToolbar>
                    <IonCardSubtitle className="ion-text-center">Total Amount: Nu.{total.toFixed(2)} </IonCardSubtitle>
                </IonCardHeader>
                {error && <p className="ion-text-center"><IonLabel color="danger">{error}</IonLabel></p>}
                {paymentMode == 'card' ?
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="stacked">Your Name</IonLabel>
                            <IonInput value={name} onIonChange={(e) => setName(String(e.detail.value))} required />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">Card Number</IonLabel>
                            <IonInput type="number" value={cardNumber} onIonChange={(e) => setCardNumber(String(e.detail.value))} required />
                        </IonItem>
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="stacked">Expiry</IonLabel>
                                    <IonInput type="month" min="2025-01" value={exp} onIonChange={(e) => setExp(String(e.detail.value))} required />
                                </IonItem>
                            </IonCol>
                            <IonCol>
                                <IonItem>
                                    <IonLabel position="stacked">CVV</IonLabel>
                                    <IonInput max={1000} maxlength={3} type="number" value={cvv} onIonChange={(e) => setCvv(String(e.detail.value))} required />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <p style={{ fontSize: "small" }} className="ion-padding">
                            All card payments are routed through <a href="https://stripe.com" target='_blank'>our Third Party Payment Gatewaya</a>. 
                            By Proceeding you agree to our <a href="/terms-and-conditions">Terms & Conditions</a>
                        </p>
                        {paymentSuccess ? (
                            <p>Payment Successful!</p>
                        ) : (
                            <IonButton className="ion-padding ion-float-right" color="drukre" type="submit" disabled={!purchaserData.isUserFormValid}>
                                <IonIcon icon={checkmarkSharp} />&nbsp; Pay Now
                            </IonButton>
                        )}
                    </IonCardContent>  
                : paymentMode == 'rma' ?
                    <RMAPaymentGateway total={total} payFunction={handlePayment} purchaserData={purchaserData} onSuccess={onSuccess} />
                : authValues.authenticated ? 
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="stacked">Your Wallet Balance : Nu. {walletBalance}</IonLabel>
                        </IonItem>
                        { walletBalance > total ?
                            <>
                                <IonItem>
                                    <IonLabel> Your order will be placed by fund deducted from your wallet. </IonLabel>
                                </IonItem>
                                <IonItem>
                                    <IonButton className="ion-padding ion-float-right" color="drukre" type="submit" disabled={!purchaserData.isUserFormValid}>
                                        <IonIcon icon={checkmarkSharp} />&nbsp; Pay With Wallet
                                    </IonButton>
                                </IonItem>
                            </>
                        :
                            <>
                                <IonItem>
                                    <IonLabel position="stacked">Add fund to your wallet. </IonLabel>
                                </IonItem>
                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonLabel>Bank Name</IonLabel>
                                            <IonText>Bank of Bhutan</IonText>
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Account Name</IonLabel>
                                            <IonText>EThrom</IonText>
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Account Number</IonLabel>
                                            <IonText>219127850</IonText> &nbsp; &nbsp;
                                            <IonButton onClick={() => copyToClipboard('219127850')}>
                                                <IonIcon icon={copySharp} />&nbsp;
                                                Copy</IonButton>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                                <IonRow className='ion-padding'>
                                    <IonInput type="number" label="Amount" 
                                        onIonChange={(e) => setStagedAmount(Number(e.detail.value))} 
                                        labelPlacement="floating" fill="outline" required />
                                </IonRow>
                                <IonRow className='ion-padding'>
                                    <IonInput type="text" label="Reference #/Description"
                                        onIonChange={(e) => setStagedRef(String(e.detail.value))} 
                                        labelPlacement="floating" fill="outline" required />
                                </IonRow>
                                <IonItem>
                                    <IonButton className="ion-padding ion-float-right" onClick={addFundToWallet} color="drukre" 
                                        disabled={!authValues.authenticated}>
                                        <IonIcon icon={checkmarkSharp} />&nbsp; Submit Now
                                    </IonButton>
                                </IonItem>
                             </>
                        }
                    </IonCardContent> 
                    : 
                    <IonCardContent>
                        <IonItem>
                            <IonLabel position="stacked">Please login first to use Wallet.</IonLabel>
                        </IonItem>
                    </IonCardContent>
                }
            </IonCard>
        </form>
    );
};

export default PaymentForm;

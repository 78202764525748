import { Store } from "pullstate";

const initialState = JSON.parse(localStorage.getItem('drukshop_favStore')) || {
    total: 0,
    product_ids: []
};

export const FavouritesStore = new Store(initialState);

export const addToFavourites = (categorySlug, productID) => {
    FavouritesStore.update(s => {
        if (s.product_ids.find(id => id === `${ categorySlug }/${ parseInt(productID) }`)) {
            s.product_ids = s.product_ids.filter(id => id !== `${ categorySlug }/${ parseInt(productID) }`);
        } else {
            s.product_ids = [ ...s.product_ids, `${ categorySlug }/${ parseInt(productID) }` ];
        }
    });

    localStorage.setItem('drukshop_favStore', JSON.stringify(FavouritesStore.getRawState()));
}
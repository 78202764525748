import axios, { isCancel, AxiosError } from "axios";
import { Preferences } from '@capacitor/preferences';

const axiosCall = async (url, method, payLoad, includeToken = false, contentType = 'application/json') => {

    const api = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL}`
    });

    const headers = {
        'Content-Type': contentType
    };

    if (includeToken) {

        var token = Preferences.get({ key: 'accessToken' });

        await token.then(async function (tokenData) {

            if (tokenData.value) {
                headers.Authorization = `Bearer ${tokenData.value}`;
            }
        });
    }

    /*
    if(captchaAction) {
      await grecaptcha.enterprise.ready(async () => {
        const captchaToken = await grecaptcha.enterprise.execute('6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd', {action: "LOGIN"});
        payLoad.captchaToken = captchaToken;
      });
    }
    */

    //api.defaults.withCredentials = true;

    let response;

    try {
        /*
        api.get('/sanctum/csrf-cookie').then(response => {
          console.log(response);
          // Login...
        });
        */
        if (method === "post") {
            response = await api.post(url, payLoad, { headers: headers });
        } else if (method === "put") {
            response = await api.put(url, payLoad, { headers: headers });
        } else if (method === "patch") {
            response = await api.patch(url, payLoad, { headers: headers });
        } else {
            response = await api.get(url, { headers: headers, params: payLoad });
        }
    } catch (e) {
        // catch error and throw it
        console.log(e);

        // Flatten data.message if it is an object
        let errorMessage = e.message;
        if (e.response && e.response.data && typeof e.response.data.message === 'object') {
            const flattenedMessage = flattenObject(e.response.data.message);
            errorMessage = JSON.stringify(flattenedMessage);
        } else if (e.response && e.response.data && typeof e.response.data.message === 'string') {
            errorMessage = e.response.data.message;
        }

        throw new Error(errorMessage);
    }

    // if success return value
    return response;

};

const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const propName = parent ? `${parent}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flattenObject(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
    }
    return res;
};

export default axiosCall;
import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, 
    IonInfiniteScroll, IonInfiniteScrollContent, IonNote, IonPage, IonProgressBar, IonRow, 
    IonSearchbar, IonTitle, IonToolbar } from "@ionic/react";
import { cart, cartOutline, chevronBackOutline, search, searchOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router"
import ProductCard from "../components/ProductCard";

import { CartStore } from "../data/CartStore";
import { ProductStore } from "../data/ProductStore";

import styles from "./CategoryProducts.module.css";

const SearchProducts = () => {

    const history = useHistory();

    const params = history.location.state as { data: string };

    const [query, setQuery] = useState<any | null>(params?.data);

    const cartRef = useRef<HTMLIonIconElement>(null);
    
    const products = ProductStore.useState(s => s.products);
    const shopCart = CartStore.useState(s => s.product_ids);
    const [ searchResults, setSearchResults ] = useState<any[]>([]);
    const [ amountLoaded, setAmountLoaded ] = useState(6);

    const [buffer, setBuffer] = useState(0.06);
	const [progress, setProgress] = useState(0);

    const filter = (data: any) => {

        setProgress(0);
        setBuffer(0.06);

        // Set progress to 25% when fetching starts
        setProgress(0.25);

        setSearchResults([]);
        data = data.toLowerCase();
        const tempResults = products.filter((category: any) => {
            category.products.filter((product: any) => {
               if(product.description.toLowerCase().indexOf(data) > -1 || product.name.toLowerCase().indexOf(data) > -1) {
                    let res = {product: product, category: category};
                    setSearchResults(prev => [...prev, res]);
                }
            });

            // Increment progress by 25% every second after fetching is complete
            const interval = setInterval(() => {
                setProgress((prevProgress) => prevProgress >= 1 ? 1 : prevProgress + 0.25);
                setBuffer((prevBuffer) => prevBuffer >= 1 ? 1 : prevBuffer + 0.25);
            }, 1000);

            // Clear interval when progress reaches 100%
            if (progress >= 1) {
                clearInterval(interval);
            }
       });
    }
    useEffect(() => {
        if(products.length <1)
            return;

        filter(query);
        
    }, [ products ]);

    const fetchMore = async (e: { target: { complete: () => void; }; }) => {

		//	Increment the amount loaded by 6 for the next iteration
		setAmountLoaded(prevAmount => (prevAmount + 6));
		e.target.complete();
	}

    const searchProducts = async () => {

        const searchVal = query;

        if (searchVal !== "") {
            filter(searchVal);
            
        } else {

            //setsearchResults(category.products);
        }
    }

    return (

        <IonPage id="category-page" className={ styles.categoryPage }>
            <IonHeader>
                <IonToolbar>

                    <IonButtons slot="end">
                        <IonBadge color="dark">
                            { shopCart.length }
                        </IonBadge>
						<IonButton color="dark" routerLink="/cart">
							<IonIcon ref={ cartRef } className="animate__animated" icon={ cart } />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				
			</IonHeader>
			
			<IonContent fullscreen>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="dark" routerLink="/" routerDirection="back">
                            <IonIcon color="dark" icon={ chevronBackOutline } />&nbsp;Categories
                        </IonButton>
                    </IonButtons>

                    <IonButtons slot="end" class="ion-padding-left">
                        <IonButton color="drukre" routerLink="/cart">
							{ shopCart.length }
							<IonIcon ref={ cartRef } className="animate__animated" icon={ cartOutline } />
						</IonButton>
					</IonButtons>
				</IonToolbar>

                <IonGrid>

                    <IonRow>
                        <IonCol size='9'>
                            <IonSearchbar className={ styles.search } onIonInput={e => setQuery(e.target.value)} value={query} placeholder="Try 'high back'" searchIcon={ searchOutline } animated={ true } />
                        </IonCol>
                        <IonCol size='2' className="ion-align-self-center">
                            <IonButton color="drukre" size="large" onClick={() => searchProducts()}>
                                <IonIcon icon={search} />&nbsp;
                                <span className="ion-hide-md-down">Search</span>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
						<IonCol>
							<IonProgressBar color='drukre' buffer={buffer} value={progress}></IonProgressBar>
						</IonCol>
					</IonRow>
                    <IonRow className="ion-text-center">
                        <IonCol size="12">
                            <IonNote>{ searchResults && searchResults.length } { (searchResults.length > 1 || searchResults.length === 0) ? " products" : " product" } found</IonNote>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        { searchResults && searchResults.map((product, index) => {

                            if ((index <= amountLoaded) && product.product.images) {
                                return (
                                    <ProductCard key={ `search_product_${ index }`} product={ product.product } category={product.category} index={ index } cartRef={ cartRef } />
                                );
                            }
                        })}
                    </IonRow>
                </IonGrid>

                <IonInfiniteScroll threshold="100px" onIonInfinite={ fetchMore }>
					<IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Fetching more...">
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );
}

export default SearchProducts;
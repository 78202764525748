import { Store } from "pullstate";

export const FeaturedStore = new Store({
    
    products: [],
    hasLoaded: false,
    pagination: {
        to: 0,
        total: 0,
        current_page: 0,
        per_page: 0,
        last_page: 0
    }
});
import React, { useEffect, useState } from "react";

import { Preferences } from '@capacitor/preferences';

import axiosCall from './data/axios';
import { IonSpinner } from "@ionic/react";

interface Props {
    children: React.ReactNode;
  }

export const Context = React.createContext<any>(undefined);

export const AuthProvider: React.FC<Props> = ({children}) => {

    const [authValues, setAuthValues] = React.useState<any>({
        authenticated: false,
        user: null,
        isLoading: true
    });

    const history = require("history").createBrowserHistory();

    const updateUser = (newUserData: any) => {
        setAuthValues(newUserData);
    };
    
    const [refreshed, setRefreshed ] = useState(false);

    const fetchUser = async () => {

        if(authValues.authenticated == false && authValues.isLoading == true){

            let userData = await axiosCall("/api/user", "get", null, true)
                .then((res: any) => {
                    setAuthValues({
                        authenticated: true,
                        user: res,
                        isLoading: false
                    });
                })
                .catch((error: any)=>{
                    if(error.message.includes('Unauthenticated') && !refreshed){
                        setRefreshed(true);
                        //refresh();
                        //Set the authvalues as we dont refresh currently
                        setAuthValues({
                            authenticated: false,
                            user: {},
                            isLoading: false
                        });
                    } else {
                        setAuthValues({
                            authenticated: false,
                            user: {},
                            isLoading: false
                        });
                    }
                });
    
        } else {
            setAuthValues({
                authenticated: false,
                user: {},
                isLoading: false
            });
        }
    }

    //const [rerender, setRerender] = useState(false);

    useEffect(()=>{

        if (authValues.isLoading) {
            fetchUser();
          }
        
    }, [authValues.isLoading]);

    const refresh = async () => {

        let refreshToken = Preferences.get({ key: 'refreshToken' });

        let result = await refreshToken.then(async function(tokenData){
            await axiosCall('api/token/refresh/', "post", {'refresh': tokenData.value})
            .then((res: any) => {
                Preferences.set({
                    key: 'accessToken',
                    value: res.data.access,
                });
                window.location.reload();
            }).catch((error: any) => {
                setAuthValues({
                    authenticated: false,
                    user: {},
                    isLoading: false
                });
            });
        });

        return result;
    };

    const logout = () => {

        Preferences.remove({ key: 'accessToken' });
    
        //Preferences.remove({ key: 'refreshToken' });

        setAuthValues({
            authenticated: false,
            user: {},
            isLoading: false
        });

        axiosCall("/api/logout", 'post', null, true)
        .then((res: any) => {
            
            })
            .catch((error: any)=>{

            });
    
        // Use history.push to redirect to login page
        history.push('/login');
    };

    let state = {
        authValues,
        setAuthValues,
        logout,
        updateUser,
        fetchUser
    };
    
    if(authValues.isLoading)
        return <IonSpinner name="lines-sharp" color="drukre" style={{display: 'flex', width:'100px', marginLeft: 'auto', marginRight: 'auto', justifyContent:'center', alignItems:'center', height: '100vh'}}></IonSpinner>;

    return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context;



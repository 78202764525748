import {
	IonCol, IonContent, IonGrid, IonIcon, IonLabel, IonNote, IonRow, IonThumbnail, IonImg,
	IonSelect,
	IonSelectOption, IonList,
	IonItem,
	IonInput,
	IonFooter,
	IonPage,
	IonTitle,
	IonHeader,
	IonToolbar,
	useIonModal,
	IonButtons,
	IonButton,
	useIonLoading,
	useIonToast,
	IonSlide,
	IonSlides,
	useIonAlert,
	IonSearchbar,
	IonTextarea,
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonCardTitle,
	IonCardSubtitle,
	IonDatetime,
	IonText
} from '@ionic/react';
import {
	globeOutline, heartOutline, locationOutline, navigateOutline, phonePortraitOutline, closeOutline,
	idCardOutline, cameraOutline, information, pricetagOutline, mailOutline, desktopOutline, checkmark, alertSharp,
	alertOutline, checkmarkSharp
} from "ionicons/icons";
import { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import axiosCall from '../data/axios';

import { CategoryStore } from '../data/CategoryStore';

import { getCaptchaToken } from "../Helper";

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import PaymentForm from './PaymentGateway';
import { set } from 'date-fns';

export const Sponsor = ({ product, onDismiss, afterEvent }) => {

	defineCustomElements(window);
	
	const categories = CategoryStore.useState(s => s.categories);

	const id = product?.id;
	const category = categories.filter((cat) => cat.id == product?.category_id);
	const [images, setImages] = useState(product?.images ? product.images : []);
	const [label, setLabel] = useState(product?.name);

	const [ purchaserData, setPurchaserData ] = useState({
        isUserFormValid: false
    });

	const [totalAmt, setTotalAmt] = useState(0);

	const [data, setData] = useState({
		inventory_id: id
	});

	//DATE RANGE
	const [dates, setDates] = useState([
    	{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);

	const handleDateSelect = (ranges) => {
		setDates([ranges.selection]);
	};
	//END DATE RANGE

	useEffect(() => {

		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate comparison
	  
		const { startDate, endDate } = dates[0];
	  
		// Format dates to 'YYYY-MM-DD'
		const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
		const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
	  
		// Convert totalAmt to a number
		const totalAmtNumber = Number(totalAmt);

		// Check if totalAmt is a number and greater than 0
		if (isNaN(totalAmtNumber) || totalAmtNumber <= 0 || startDate.setHours(0, 0, 0, 0) <= currentDate 
		|| endDate.setHours(0, 0, 0, 0) <= currentDate || startDate >= endDate) {
			setPurchaserData({
				...purchaserData,
				isUserFormValid: false
			});
			return;
		} else {
			setPurchaserData({
				...purchaserData,
				isUserFormValid: true
			});
		}

		setData({
			...data,
			startDate: formattedStartDate,
			endDate: formattedEndDate,
			payment_amount: totalAmtNumber
		});

	}, [totalAmt, dates[0].startDate, dates[0].endDate]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonRow>
						<IonCol size="9">
							<IonTitle>Feature the Product</IonTitle>
						</IonCol>
						<IonCol size="3" className="ion-float-end">
							<IonIcon className="ion-float-right" icon={closeOutline} onClick={() => onDismiss()} />
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonGrid className="ion-padding">
					<IonRow>
						<IonCol size='12'>
							<IonList>
								Product Category: {category[0]['description']}
							</IonList>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<Swiper slidesPerView={5} initialSlide={2} speed={400} spaceBetween={10} centeredSlides={true}
								zoom={true} key={images.map(slide => slide.id).join('_')}>
								{images.map((image, i) => {
									//if(!image.src.includes('undefined')) { 
									 	return <SwiperSlide key={i}>
												<div className="swiper-zoom-container">
													<IonImg src={image.src} />
												</div>
											</SwiperSlide>
									//}
								}
								)}
							</Swiper>
						</IonCol>
					</IonRow>

					<IonRow className="ion-margin-bottom">
						<IonCol size="12">
							<IonItem>
								Product Name: {label}
							</IonItem>
						</IonCol>
					</IonRow>

					<IonRow className="ion-margin-bottom">
						<IonCol size="12">
							<p style={{ fontSize: "small" }}>
								The way the featured product is ranked is the amount divided the number of days you select.
							</p>
						</IonCol>
					</IonRow>

					
					<IonRow>
						<IonCol size="12" className="ion-text-center">
							<IonLabel>Choose the dates to feature the product</IonLabel>
							<DateRange ranges={dates} minDate={new Date()} onChange={handleDateSelect}/>
						</IonCol>
						<IonCol size="12">
							<IonItem>
								<IonLabel position="stacked">Total Amount</IonLabel>
								<IonInput type="number" value={totalAmt} onIonChange={(e) => setTotalAmt(e.detail.value)} required />
							</IonItem>
						</IonCol>
					</IonRow>

					<IonRow className="ion-margin-bottom">
						<IonCol size="12">
						<PaymentForm payLoad={data} 
                                api= '/api/sponsor' total={Number(totalAmt)} onSuccess={afterEvent} purchaserData={purchaserData} />
						</IonCol>
					</IonRow>

				</IonGrid>
			</IonContent>
			<IonFooter>
				<IonRow>
					<IonCol size="12" className="ion-text-end">
						
					</IonCol>
				</IonRow>
			</IonFooter>
		</IonPage>
	);
}
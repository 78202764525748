

export const coreLocations =
{
	"thimphu_town": "Thimphu Town & Area",
	"outer_thimphu": "Outer Thimphu",
	"paro_town": "Paro Town & Area",
	"outer_paro": "Outer Paro",
	"phuentsholing_town": "Phuentsholing Town & Area",
	"outer_phuentsholing": "Outer Phuentsholing",
	"gelephu_town": "Gelephu Town & Area",
	"outer_gelephu": "Outer Gelephu",
	"punakha_town": "Punakha Town & Area",
	"outer_punakha": "Outer Punakha",
	"wangdue_town": "Wangdue Town & Area",
	"outer_wangdue": "Outer Wangdue",
	"bumthang_town": "Bumthang Town & Area",
	"outer_bumthang": "Outer Bumthang",
	"chukha_town": "Chukha Town & Area",
	"dagana_town": "Dagana Town & Area",
	"gasa_town": "Gasa Town & Area",
	"gantey_town": "Gantey Town & Area",
	"gomtu_town": "Gomtu Town & Area",
	"gyelposhing_town": "Gyelposhing Town & Area",
	"haa_town": "Haa Town & Area",
	"kanglung_town": "Kanlung Town & Area",
	"khaling_town": "Khaling Town & Area",
	"lobesa_town": "Lobesa Town & Area",
	"lhuentse_town": "Lhuentse Town & Area",
	"mongar_town": "Mongar Town & Area",
	"nanglam_town": "Nanglam Town & Area",
	"pemagatshel_town": "Pemagatshel Town & Area",
	"samdrupjongkhar_town": "Samdrupjongkhar Town & Area",
	"samtse_town": "Samtse Town & Area",
	"sarpang_town": "Sarpang Town & Area",
	"trongsa_town": "Trongsa Town & Area",
	"trashigang_town": "Trashigang Town & Area",
	"tsirang_town": "Tsirang Town & Area",
	"trashiyangtse_town": "Trashiyangtse Town & Area",
	"wamrong_town": "Wamrong Town & Area",
	"zhemgang_town": "Zhemgang Town & Area"
};

export const dzongkhags =
{
	"Paro": [
		"Doga",
		"Dopshari",
		"Doteng",
		"Hungrel",
		"Lamgong",
		"Lungnyi",
		"Naja",
		"Shapa",
		"Tsento",
		"Wangchang"
	],
	"Thimphu": [
		"Chang",
		"Dagala",
		"Genyekha",
		"Kawang",
		"Lingzhi",
		"Mewang",
		"Naro",
		"Soe"
	],
	"Bumthang": [
		"Chhume",
		"Choekor",
		"Tang",
		"Ura"
	],
	"Chukha": [
		"Bjacho",
		"Bongo",
		"Chapcha",
		"Dala",
		"Dungna",
		"Geling",
		"Getena",
		"Logchina",
		"Metakha",
		"Phuentsholing",
		"Sampheling"
	],
	"Dagana": [
		"Dorona",
		"Drujegang",
		"Gesarling",
		"Goshi",
		"Kana",
		"Karmaling",
		"Khebisa",
		"Lajab",
		"Lhamoy Zingkha",
		"Nichula",
		"Tashiding",
		"Tsangkha",
		"Tsendagang",
		"Tseza"
	],
	"Gasa": [
		"Goenkhamae",
		"Goenkaatoe",
		"Laya",
		"Lunana"
	],
	"Punakha": [
		"Chhubu",
		"Dzomo",
		"Goenshari",
		"Guma",
		"Kabjisa",
		"Lingmukha",
		"Shenga Bjime",
		"Talog",
		"Toepisa",
		"Towang"
	],
	"Samtse": [
		"Dungtoe",
		"Dophoogchen",
		"Denchukha",
		"Namgaychhoeling",
		"Norbugang",
		"Norgaygang",
		"Pemaling",
		"Phuentshogpelri",
		"Samtse",
		"Sangngagchhoeling",
		"Tading",
		"Tashicholing",
		"Tendu",
		"Ugentse",
		"Yoeseltse"
	],
	"Tsirang": [
		"Barshong",
		"Dunglegang",
		"Goseling",
		"Kikhorthang",
		"Mendrelgang",
		"Patshaling",
		"Phutenchhu",
		"Rangthangling",
		"Semjong",
		"Sergithang",
		"Tsholingkhar",
		"Tsirangtoe"
	],
	"Wangdue Phodrang": [
		"Athang",
		"Bjena",
		"Daga",
		"Dangchu",
		"Gangte",
		"Gasetsho Gom",
		"Gasetsho Om",
		"Kazhi",
		"Nahi",
		"Nyisho",
		"Phangyuel",
		"Phobji",
		"Ruepisa",
		"Sephu",
		"Thedtsho"
	],
	"Sarpang": [
		"Chhuzagang",
		"Chhudzom",
		"Dekiling",
		"Gakiling",
		"Gelephu",
		"Jigmechhoeling",
		"Samtenling",
		"Senghe",
		"Serzhong",
		"Shompangkha",
		"Tareythang",
		"Umling"
	],
	"Trongsa": [
		"Dragteng",
		"Korphu",
		"Langthil",
		"Nubi",
		"Tangsibji"
	],
	"Zhemgang": [
		"Bardho",
		"Bjoka",
		"Goshing",
		"Nangkor",
		"Ngangla",
		"Phangkhar",
		"Shingkhar",
		"Trong"
	],
	"Haa": [
		"Bji",
		"Gakiling",
		"Katsho",
		"Sama",
		"Sangbay",
		"Uesu"
	],
	"Lhuntse": [
		"Gangzur",
		"Jaray",
		"Khoma",
		"Kurtoe",
		"Menbi",
		"Metsho",
		"Minjay",
		"Tsenkhar"
	],
	"Mongar": [
		"Balam",
		"Chaskhar",
		"Chhali",
		"Drametse",
		"Drepung",
		"Gongdue",
		"Jurmey",
		"Kengkhar",
		"Mongar",
		"Narang",
		"Ngatshang",
		"Saleng",
		"Sherimung",
		"Silambi",
		"Thangrong",
		"Tsakaling",
		"Tsamang"
	],
	"Pemagatshel": [
		"Chhimung",
		"Choekhorling",
		"Chongshing Borang",
		"Dechenling",
		"Dungme",
		"Khar",
		"Nanong",
		"Norbugang",
		"Shumar",
		"Yurung",
		"Zobel"
	],
	"Samdrup Jongkhar": [
		"Dewathang",
		"Gomdar",
		"Langchenphu",
		"Lauri",
		"Martshala",
		"Orong",
		"Pemathang",
		"Phuntshothang",
		"Samrang",
		"Serthi",
		"Wangphu"
	],
	"Trashigang": [
		"Bartsham",
		"Bidung",
		"Kanglung",
		"Kangpara",
		"Khaling",
		"Lumang",
		"Merak",
		"Phongmey",
		"Radhi",
		"Sakteng",
		"Samkhar",
		"Shongphu",
		"Thrimshing",
		"Uzorong",
		"Yangneer"
	],
	"Trashiyangtse": [
		"Bumdeling",
		"Jamkhar",
		"Khamdang",
		"Ramjar",
		"Toetsho",
		"Tomzhangsa",
		"Trashiyangtse",
		"Yalang"
	]
};


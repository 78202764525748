import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { personCircle, eye, eyeOff } from "ionicons/icons";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonAlert } from '@ionic/react';
import axiosCall from '../data/axios';
import { Preferences } from '@capacitor/preferences';

import AuthContext from "../UserContext";
import { getCaptchaToken } from "../Helper";

import UserStatus from "../components/UserStatus";

function validateEmail(email: string) {
	const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
	return re.test(String(email).toLowerCase());
}

declare var grecaptcha: any;

const Auth: React.FC = () => {
	const history = useHistory();
	const [email, setEmail] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [re_password, setRePassword] = useState<string>("");
	const [name, setName] = useState<string>();
	const [iserror, setIserror] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("");
	const [mode, setMode] = useState<string>(window.location.pathname);
	const { code }: any = useParams();

	const { authValues, fetchUser, logout } = React.useContext(AuthContext);

	const spinnerOptions = {
		message: 'Please wait...',
		animated: true,
		cssClass: 'drukre-loading',
		showBackdrop: true
	};

	const [spinnerPresent, spinnerDismiss] = useIonLoading();

	if (mode.indexOf('/chanel') > -1) {

		if (code) {

			const veriData = {
				"code": code
			}

			//spinnerPresent(spinnerOptions);

			axiosCall("/api/verifyEmailPhone", 'post', veriData)
				.then((res: any) => {
					//spinnerDismiss();
					//history.push("/login");
				})
				.catch((error: any) => {
					//setMessage("Unable to verify account. Please try again later.");
					//setIserror(true);
					//spinnerDismiss();
				});
		}
	} else if (authValues.authenticated)
		history.goBack();
	/*
	useEffect(() => {
		// Add reCaptcha
		const script = document.createElement('script');
		script.src = "https://www.google.com/recaptcha/enterprise.js?render=6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		}
	}, [])
	*/

	function clickMode(newMode: React.SetStateAction<string>) {

		setMode(newMode);

	};

	/*
	function getCaptchaToken(action: any) {
		return new Promise((res, rej) => {
			grecaptcha.enterprise.ready(() => {
				grecaptcha.enterprise.execute('6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd', {action: action}).then((token: any) => {
					return res(token);
				})
			})
		})
	}
	*/

	const handleLogin = async () => {
		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}
		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}

		if (!password || password.length < 6) {
			setMessage("Please enter your password");
			setIserror(true);
			return;
		}

		const loginData = {
			"email": email,
			"password": password,
			"captchaToken": null,
			"captchaAction": ''
		}

		spinnerPresent(spinnerOptions);

		/*await grecaptcha.enterprise.ready(async () => {
		  const captchaToken = await grecaptcha.enterprise.execute('6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd', {action: "LOGIN"});
		  loginData.captchaToken = captchaToken;
		});*/

		await getCaptchaToken('LOGIN').then((token: any) => { // Here wait token generated
			if (token) {
				loginData.captchaToken = token;
				loginData.captchaAction = 'LOGIN';
			}
		});

		axiosCall("/api/login", 'post', loginData, false, 'application/json')
			.then((res: any) => {
				spinnerDismiss();
				Preferences.set({
					key: 'accessToken',
					value: res.data.access_token,
				});
				//fetchUser();
				//history.push("/agentmap");
				//history.goBack();
				window.open("/my-products", "_self");
			})
			.catch((error: any) => {
				setMessage("Failed to authenticate! " + error.message);
				setIserror(true);
				spinnerDismiss();
			});

	};

	const handleRegistration = async () => {
		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}
		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}
		if (!phone) {
			setMessage("Please enter a valid phone");
			setIserror(true);
			return;
		}

		const regData = {
			"name": name,
			"email": email,
			"phone": phone,
			"captchaToken": null,
			"captchaAction": ''
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		await getCaptchaToken('SIGNUP').then((token: any) => { // Here wait token generated
			if (token) {
				regData.captchaToken = token;
				regData.captchaAction = 'SIGNUP';
			}
		});

		axiosCall("/api/register", 'post', regData, true)
			.then((res: any) => {
				spinnerDismiss();
				history.push("/login");
				setMessage("Please check your email for a link. ");
			})
			.catch((error: any) => {
				setMessage(error.message);
				setIserror(true);
				spinnerDismiss();
			});

	};

	const handlePasswordReset = async () => {

		if (!email) {
			setMessage("Please enter a valid email");
			setIserror(true);
			return;
		}

		if (validateEmail(email) === false) {
			setMessage("Your email is invalid");
			setIserror(true);
			return;
		}
		const resetData = {
			"email": email,
			"captchaToken": null,
			"captchaAction": ''
		}

		await getCaptchaToken('RESET').then((token: any) => { // Here wait token generated
			if (token) {
				resetData.captchaToken = token;
				resetData.captchaAction = 'RESET';
			}
		});

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/resetPassword", 'post', resetData, true)
			.then((res: any) => {
				spinnerDismiss();
				setMessage("Password Reset Email sent. Please check your email.");
			})
			.catch((error: any) => {
				setMessage("Reset request failed. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});
	};

	const handleVerification = () => {

		if (!password || password.length < 6) {
			setMessage("Please enter your password");
			setIserror(true);
			return;
		}

		if (password != re_password) {
			return;
		}

		const regData = {
			"password": password,
			"re_password": password,
			"code": code
		}

		spinnerPresent(spinnerOptions);

		const headers = {
			'Content-Type': 'application/json'
		}

		axiosCall("/api/verifyEmail", 'post', regData, true)
			.then((res: any) => {
				spinnerDismiss();
				history.push("/login");
			})
			.catch((error: any) => {
				setMessage("Unable to verify account. " + error.message);
				setIserror(true);
				spinnerDismiss();
			});

	};

	const [showPassword, setShowPassword] = useState(false);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Login</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding ion-text-center">
				<IonGrid>
					<IonRow>
						{mode == '/register' ?
							<IonCol size-md="6" offset-md="3">
								<IonRow>
									<IonCol>
										<IonLabel color="danger">{message}</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<IonIcon
											color="drukre"
											style={{ fontSize: "70px" }}
											icon={personCircle}
										/>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonItem>
											<IonLabel position="stacked"> Name </IonLabel>
											<IonInput type="text" onIonChange={(e) => setName(e.detail.value!)}>
											</IonInput>
										</IonItem>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonItem>
											<IonLabel position="stacked"> Mobile Number</IonLabel>
											<IonInput type="number" onIonChange={(e) => setPhone(e.detail.value!)}>
											</IonInput>
										</IonItem>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<IonItem>
											<IonLabel position="stacked"> Email</IonLabel>
											<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)} >
											</IonInput>
										</IonItem>
									</IonCol>
								</IonRow>

								<IonRow>
									<IonCol>
										<p style={{ fontSize: "small" }}>
											By clicking REGISTER you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
										</p>
										<IonButton color="drukre" expand="block" onClick={handleRegistration}>Register</IonButton>
										<p style={{ fontSize: "medium" }}>
											Already have an account? <a onClick={() => clickMode('/login')}>Sign In!</a>
										</p>

									</IonCol>
								</IonRow>
							</IonCol>
							: mode == '/resetpassword' ?
								<IonCol size-md="6" offset-md="3">
									<IonRow>
										<IonCol>
											<IonLabel color="danger">{message}</IonLabel>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonIcon
												color="drukre"
												style={{ fontSize: "70px" }}
												icon={personCircle}
											/>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonItem>
												<IonLabel position="stacked"> Email</IonLabel>
												<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)}>
												</IonInput>
											</IonItem>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonButton color="drukre" expand="block" onClick={handlePasswordReset}>Reset Password</IonButton>
										</IonCol>
									</IonRow>
								</IonCol>
								: mode.indexOf('/verifyemail') > -1 ?
									<IonCol size-md="6" offset-md="3">
										<IonRow>
											<IonCol>
												<IonLabel color="danger">{message}</IonLabel>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol>
												<IonIcon
													color="drukre"
													style={{ fontSize: "70px" }}
													icon={personCircle}
												/>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonItem>
													<IonLabel position="stacked"> Password</IonLabel>
													<IonInput type="password" onIonChange={(e) => setPassword(e.detail.value!)} >
													</IonInput>
												</IonItem>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonItem>
													<IonLabel position="stacked"> Re Enter Password</IonLabel>
													<IonInput type="password" onIonChange={(e) => setRePassword(e.detail.value!)} >
													</IonInput>
													{re_password != password && <p style={{ fontSize: "small", color: "red", textAlign: "center" }}>Passwords did not match!</p>}
												</IonItem>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<p style={{ fontSize: "small" }}>
													By clicking REGISTER you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
												</p>
												<IonButton color="drukre" expand="block" onClick={handleVerification}>Verify</IonButton>
												<p style={{ fontSize: "medium" }}>
													Already have an account? <a onClick={() => clickMode('/login')}>Sign In!</a>
												</p>

											</IonCol>
										</IonRow>
									</IonCol>
									: !authValues.authenticated ?
										<IonCol size-md="6" offset-md="3">
											<IonRow>
												<IonCol>
													<IonLabel color="danger">{message}</IonLabel>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<IonIcon
														color="drukre"
														style={{ fontSize: "70px" }}
														icon={personCircle}
													/>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<IonItem>
														<IonLabel position="stacked"> Email</IonLabel>
														<IonInput type="email" value={email} onIonChange={(e) => setEmail(e.detail.value!)}>
														</IonInput>
													</IonItem>
												</IonCol>
											</IonRow>

											<IonRow>
												<IonCol>
													<IonItem lines="none" style={{ display: "flex", alignItems: "center" }}>
														<IonLabel position="stacked"> Password</IonLabel>
														<IonInput
															type={showPassword ? "text" : "password"}
															value={password}
															onIonChange={(e) => setPassword(e.detail.value!)}
														/>
														<IonIcon
															slot="end"
															icon={showPassword ? eyeOff : eye}
															onClick={() => setShowPassword(!showPassword)}
														/>
													</IonItem>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<p style={{ fontSize: "small" }}>
														By clicking LOGIN you agree to our <span onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</span>
													</p>
													<IonButton color="drukre" expand="block" onClick={handleLogin}>Login</IonButton>
													<p style={{ fontSize: "medium" }}>
														Don't have an account? <a onClick={() => clickMode('/register')}>Sign up!</a> |
														Forgot Password? <a onClick={() => clickMode('/resetpassword')}>Reset!</a>
													</p>

												</IonCol>
											</IonRow>
										</IonCol>
										:
										<IonCol size-md="6" offset-md="3">
											<UserStatus />
										</IonCol>
						}
					</IonRow>
					<IonRow>
						<IonCol>
							<p style={{ fontSize: "small" }}>
								This site is protected by reCAPTCHA and the Google
								<a href="https://policies.google.com/privacy" target="_blank"> Privacy Policy</a> and
								<a href="https://policies.google.com/terms" target="_blank"> Terms of Service</a> apply.
							</p>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Auth;
import { IonRow, IonCol } from '@ionic/react';
import React from 'react';

const NotFound = () => {
  return (
    <>
        <div style={{  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop:'20%' }}>
            <h1>404 - Not Found</h1>
        </div>
        <div style={{  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <p>The page you are looking for does not exist.</p>
        </div>
    </>
  );
};

export default NotFound;
import { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
	IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardSubtitle, IonCol, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton,
	IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, IonSpinner
} from '@ionic/react';

import {
	IonReactRouter
} from '@ionic/react-router';
import styles from "../Home.module.css";
import { cartOutline, heartOutline } from 'ionicons/icons';

import { ProductStore } from '../../data/ProductStore';
import { FavouritesStore } from '../../data/FavouritesStore';
import { CartStore } from '../../data/CartStore';

const Admin = () => {

	const products = ProductStore.useState(s => s.products);
	const favourites = FavouritesStore.useState(s => s.product_ids);
	const shopCart = CartStore.useState(s => s.product_ids);

	const productHasLoaded = ProductStore.useState(s => s.hasLoaded);

	if(!productHasLoaded)
		return <IonSpinner name="lines-sharp" color="drukre" style={{display: 'flex',  marginLeft: 'auto', marginRight: 'auto', justifyContent:'center', alignItems:'center', height: '100vh'}}></IonSpinner>;

	return (
		<IonPage id="home-page" className={styles.homePage}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Categories</IonTitle>

					<IonButtons slot="start" className="ion-padding-start" color="drukre">
						<IonCardSubtitle className={styles.logo}>Bhutanese Shop</IonCardSubtitle>
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent fullscreen>
				<IonHeader collapse="condense">
					<IonToolbar>
						
					</IonToolbar>
				</IonHeader>
				<IonToolbar>
					<IonTitle size="large">
						Admin Categories
					</IonTitle>
					<IonButtons slot="end" className="ion-padding-end">
						<IonButton color="danger" routerLink="/favourites">
							{favourites.length}
							<IonIcon icon={heartOutline} />
						</IonButton>

						<IonButton color="drukre" routerLink="/cart">
							{shopCart.length}
							<IonIcon icon={cartOutline} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				{/*
				<IonToolbar>
					<IonReactRouter>
						<IonTabs>
							<IonRouterOutlet>
							<Route path="/favourites" exact>
								<FavouriteProducts />
							</Route>

							<Route path="/cart" exact>
								<CartProducts />
							</Route>
							</IonRouterOutlet>
							<IonTabBar slot="bottom" className={ styles.cartBadgeTabBar }>
								<IonTabButton tab="favourites" routerLink="/favourites">
									<IonIcon color="drukre" icon={heartOutline} />
									<IonBadge color="danger">{ favourites.length }</IonBadge>
								</IonTabButton>
								<IonTabButton tab="cart" href="/cart">
									<IonIcon color="drukre" icon={cartOutline} />
									<IonBadge color="danger">{ shopCart.length }</IonBadge>
								</IonTabButton>
							</IonTabBar>
							
						</IonTabs>
		 			</IonReactRouter>
				</IonToolbar>
			*/}
				<IonGrid>
					<IonRow>
						{products.map((category, index) => {

							return (
								<IonCol sizeXs="6" sizeSm="6" sizeMd="3" key={`category_list_${index}`} style={{display: "flex"}}>
									<IonCard routerLink={`/category/${category.slug}`} className={styles.categoryCard}>

										<img src={category.cover} alt="category cover" />

										<IonCardContent color="drukre" className={styles.categoryCardContent}>
											<IonCardSubtitle>{category.name}</IonCardSubtitle>
										</IonCardContent>
									</IonCard>
								</IonCol>
							)
						})}
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Admin;
import React, { useState, useEffect } from 'react';
import {
    IonBadge, IonButton, IonIcon,
    IonModal, IonList, IonItem, IonLabel, IonHeader, IonToolbar, IonTitle, IonContent,
    IonText,
    IonRow,
    IonCol,
    useIonLoading,
    useIonToast,
    IonNote,
    IonInput,
    useIonModal,
    IonPage,
    IonSegmentButton,
    IonSegment
} from '@ionic/react';
import { notificationsOutline, close, closeOutline, chatbubblesOutline, checkmarkSharp, copyOutline, copySharp } from 'ionicons/icons';

import './Notification.css';

import axiosCall from '../data/axios';

import { getCaptchaToken } from "../Helper";

import AuthContext from "../UserContext";

const ContactModal = ({ onDismiss, afterEvent }: { onDismiss: any, afterEvent: () => void }) => {

    const { authValues, fetchUser, logout } = React.useContext(AuthContext);

    const [error, setError] = useState('');

    const [spinnerPresent, spinnerDismiss] = useIonLoading();
    const [presentToast] = useIonToast();

    const postContact = async (event: any) => {
            
        return;

        const headers = {
            'Content-Type': 'application/json'
        }

        var captchaData = {
            captchaToken: '',
            captchaAction: ''
        };

        await getCaptchaToken('PAYMENT').then((token: any) => { // Here wait token generated
            if (token) {
                captchaData.captchaToken = token;
                captchaData.captchaAction = 'PAYMENT';
            }
        });

        spinnerPresent({
            message: 'Processing. Please wait...',
            spinner: 'bubbles',
            animated: true,
            cssClass: 'drukre-loading'
        });
        
        axiosCall("/api/contact", 'post',
            {
                contactData: 'credit',
                captchaData: captchaData
            }, true
        ).then((res: any) => {
            console.log(res);
            if (res.data.status !== 1)
                setError('Fund submission unsuccessful! ' + res.message);
            else {
                presentToast({
                    message: 'Fund submission successfully! You will be notified when it becomes available in your wallet.',
                    duration: 3000,
                    color: 'success'
                });
            }
        })
        .catch((error) => {
            setError("Fund submission unsuccessful! Please try again.");
            //setIserror(true);
        }).finally(() => {
            spinnerDismiss();
        });
    };

    return (
        <IonPage id="contact-modal">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Contact Us</IonTitle>
                    <div slot="end" className="ion-padding" onClick={() => onDismiss()}><IonIcon size="small" color="drukre" icon={closeOutline} /></div>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonRow>
                    <IonCol className="ion-text-center">
                        
                    </IonCol>
                </IonRow>
                {error && <p className="ion-text-center"><IonLabel color="danger">{error}</IonLabel></p>}
                <IonRow>
                    <IonCol className="ion-text-center">
                        Email: ethrom.info@gmail.com 
                        <br/> Phone: +975-17141619
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

const Contact = () => {

    const cModalDismiss = () => {
        setPresentContactModal()
    }

	const [presentContactModal, setPresentContactModal] = useIonModal(ContactModal, {
        onDismiss: cModalDismiss,
		afterEvent: () => { }
    });

    const contactForm = () => {
        presentContactModal({
            initialBreakpoint: 0.60,
            breakpoints: [0, 0.60, 0.75, 1],
            backdropBreakpoint: 0
        });
    };

    return (
        <span onClick={contactForm}>
            Contact Us
        </span>
    );
};

export default Contact;
